/**
*  RUF Jugendreisen Javascript Bibliothek
* <http://m.ruf.de>
*
* @date  12.08.2015
* @lastmodified  10.03.2016 (von Fynn, bitte ueberpruefen)
* @author    zwobundstahmann GmbH& Co.KG Michael Stahmann

@Mayor-Version
@version 2.2.0

 * jQuery.browser.mobile (http://detectmobilebrowser.com/)
 * * jQuery.browser.mobile will be true if the browser is a mobile device
 * **/
(function(a) {
  (jQuery.browser = jQuery.browser || {}).mobile =
    /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
      a
    ) ||
    /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
      a.substr(0, 4)
    );
})(navigator.userAgent || navigator.vendor || window.opera);

/**
 * SearchSuggest fuer ruf Reisen - Mobile
 * Dieser SearchSuggest ist angepasst auf eine definiertes HTML Rueckgabeformat.
 * @author Michael Wohlers ext. Michael Stahmann zowbundstahmann GmbH & Co.KG
 * @history 21.08.2015  --- Version 1.3 ---
 * @history 21.08.2015 11:14	Ajax timer (200 msec vor den Request) eingebaut
 * @history 12.10.2015  Umbau auf JSON-Rückgabe vom Server
 */
var that;

var rufreisenSearchSuggest = {
  id_input: "#suchfeld",
  id_suggest: "#search_suggest",
  //   url: "/assets/other/test.json?",
  url: "/data/onpagesearch/", // Url zum Suggest für die Keywords
  min_chars: 3,
  resulturl: "/data/suggest_liste/",
  init: function() {
    that = this;
    $(this.id_suggest).hide();
    $("body").click(function() {
      $(this.id_suggest).hide();
    });
    $(this.id_input).change(function() {
      var queryurl = that.resulturl + encodeURI(String($(this).val()));
      // der Infonetz-server mag keine "&"-Zeichen, selbst wenn richtig encoded
      // deshalb in der href ersetzen: & -> |and|
      queryurl = queryurl.replace("&", "|and|");
      $("#searchForm").attr("data-url", queryurl);
    });

    /*    $(this.id_input).keydown(function(event) {
      if (event.keyCode) {
        var keycode = event.keyCode;
      } else if (event.event.which) {
        var keycode = event.keyCode;
      }
      if (keycode == 13) {
        var href = that.selectCurrentMenuItem();
        if (href) {
          event.preventDefault();
          top.location.href = href;
        }
      } else if (keycode === 38) {
        that.bArrowKey = true;
        that.debug("--- Taste:ARROW-UP<br>");
        that.activatePreviousMenuItem();
      } else if (keycode === 40) {
        that.bArrowKey = true;
        that.debug("--- Taste:ARROW-DOWN<br>");
        that.activateNextMenuItem();
      } else if (keycode === 36) {
        that.bArrowKey = true;
        that.debug("--- Taste:Pos1<br>");
        that.activateFirstMenuItem();
        event.preventDefault();
      } else if (keycode === 35) {
        that.bArrowKey = true;
        that.debug("--- Taste:Ende<br>");
        that.activateLastMenuItem();
        event.preventDefault();
      } else {
        that.bArrowKey = false;
        that.debug("--- keycode=" + keycode);
      }
    });*/
    $(this.id_input).keyup(function() {
      if (that.bArrowKey) return;
      var q = new String($(this).val());
      if (that.min_chars && q.length < that.min_chars) {
        that.closeSuggest();
        return;
      }
      window.clearTimeout(that.ajax_timer);
      that.ajax_timer = window.setTimeout("that.doRequest('" + q + "')", 200);
    });
  },
  bArrowKey: false,
  nTotalMenuItems: -1,
  selectedMenuItem: -1,
  that: null,
  ajax_timer: null,
  doRequest: function(q) {
    that.debug("--- Ajax Load Content q=" + q + "<br>");
    that.deactivateMenuItems();
    $.getJSON(that.url + encodeURI(q), function(data) {
      var items = [];
      items.push(
        '<div id="suggLayer"><div class="suggItemHead"><div class="suggName help">Deine Suche auf ruf.de nach:&nbsp; <span>' +
          q +
          '</span></div><div class="suggCat help">&nbsp;</div></div>'
      );
      $.each(data, function(i) {
        var val = data[i].Wort;
        items.push(
          '<a href="' +
            that.resulturl +
            encodeURI(val.replace("&", "|and|")) +
            '" class="suggItem" id="suchzelle' +
            i +
            '"><div class="suggName">' +
            val +
            '</div><div class="suggCat" data-sug="' +
            val +
            '"></div></a>'
        );
      });
      items.push("</div>");
      $(that.id_suggest)
        .html(items.join(""))
        .show();
      $("a.suggItem")
        .mouseenter(function() {
          $(this).addClass("active");
        })
        .mouseleave(function() {
          $(this).removeClass("active");
        });
      // $('.suggItem').highlight(q);
      $(".suggCat").click(function(event) {
        event.preventDefault();
        var suggestKeyword = $(this).data("sug");
        $("#suchfeld").val(suggestKeyword);
        $("#suchfeld").trigger("change");
      });
    });
  },
  closeSuggest: function() {
    $(this.id_suggest).hide();
    this.deactivateMenuItems();
  },
  setTotalMenuItems: function(n) {
    this.nTotalMenuItems = n;
    this.debug("--- nTotalMenuItems=" + this.nTotalMenuItems);
  },
  activateNextMenuItem: function() {
    this.selectedMenuItem++;
    if (this.selectedMenuItem > this.nTotalMenuItems - 1)
      this.selectedMenuItem = this.nTotalMenuItems - 1;
    this.debug("--- selectedMenuItem=" + this.selectedMenuItem);
    this.highlightItem();
  },
  activatePreviousMenuItem: function() {
    this.selectedMenuItem--;
    if (this.selectedMenuItem < -1) this.selectedMenuItem = -1;
    this.debug("--- selectedMenuItem=" + this.selectedMenuItem);
    this.highlightItem();
  },
  activateFirstMenuItem: function() {
    this.debug("--- activateFirstMenuItem<br>");
    this.selectedMenuItem = 0;
    this.highlightItem();
  },
  activateLastMenuItem: function() {
    this.debug("--- activateLastMenuItem<br>");
    this.selectedMenuItem = this.nTotalMenuItems - 1;
    this.highlightItem();
  },
  deactivateMenuItems: function() {
    this.selectedMenuItem = -1;
    this.nTotalMenuItems = -1;
    this.highlightItem();
  },
  selectCurrentMenuItem: function() {
    if (this.selectedMenuItem < 0) return null;
    this.debug("--- selectCurrentMenuItem:" + this.selectedMenuItem);
    var item = $(this.id_suggest)
      .find("a")
      .eq(this.selectedMenuItem);
    var href = item.attr("href");
    return href;
  },
  highlightItem: function() {
    var liste = $(this.id_suggest).find("a");
    liste.removeClass("active");
    if (this.selectedMenuItem < 0) {
      return;
    }
    liste.eq(this.selectedMenuItem).addClass("active");
    this.debug("--- highlightItem=" + this.selectedMenuItem);
  },
  debug: function(str) {
    return;
  }
};
$(document).ready(function() {
  rufreisenSearchSuggest.init();
  $("#suchfeld").attr("autocomplete", "off");
  $("#suchfeld").attr("autocorrect", "off");

  // Absenden mit dem Button
  $("button.submitIcon").click(function(event) {
    event.preventDefault();
    var searchurl = $("#searchForm").attr("data-url");
    // console.log("Sucheld mit Button abgesetzt: " + searchurl);
    top.location.href = searchurl;
  });
  // Verhindern des Enterbuttons
  $("#searchForm").bind("keypress keydown keyup", function(event) {
    if (event.keyCode === 13) {
      event.preventDefault();
      var searchurl = $("#searchForm").attr("data-url");
      console.log("Sucheld mit ENTER abgesetzt: " + searchurl);
      top.location.href = searchurl;
    }
    var queryurl = "/data/suggest_liste/" + String($("#suchfeld").val());
    // der Infonetz-server mag keine "&"-Zeichen, selbst wenn richtig encoded
    // deshalb in der href ersetzen: & -> |and|
    queryurl = queryurl.replace("&", "|and|");
    $("#searchForm").attr("data-url", encodeURI(queryurl));
    // console.log("neue query-url" + queryurl);
  });
});

(function(e) {
  "use strict";
  e.fn.getInputType = function() {
    return this[0].tagName.toString().toLowerCase() === "input"
      ? e(this[0])
          .attr("type")
          .toLowerCase()
      : this[0].tagName.toLowerCase();
  };
})(jQuery);
