/**** JavaScript Document
 **
 ** Kunde: ruf reisen
 ** Projekt: ruf - mobile (m.ruf.de)
 ** Version 1.6.5
 ** Datum: 20.08.2015
 ** Autor: M. Stahmann (c) 2015 zwobunstahmann GmbH & Co.KG
 **
 ***/
// Globale Funktionen fuer abireisen.de Version 2.01 23.04.2016 / zwobundstahmann GmbH & Co.KG
$.fn.countdown = function (start,duration) {
	var intduaration = start;
    var container = $(this[0]).html(intduaration);
    var countdown = setInterval(function () {
        if (intduaration <= duration) {
            container.text(intduaration);
        } else {
			intduaration = start-1;
        }
		++intduaration
    }, 1000);

};

$("#countdownZiffer").countdown(1,5);
// Mai 2019. An wiederverwendbare klasse
$(".js-count-to-number").each(function() {
    var start = $(this).data("start") || 1;
    var end = $(this).data("end") || 5;
    $(this).countdown(start,end);
});

var InitiPage = {

    start: function() { //Bei laden oder resize einer Seite
        // (Achtung KEINE Waypoint in diesem Bereich wg. iOS M. Stahmann 23.08.2015)
        "use strict";
        var bbreite = $(window).width();
        var doc_height = $(document).height();
        doc_height = doc_height - 60;
        $("#nav-overlay").css({
            'height': doc_height,
            'width': bbreite + 'px'
        });

        // Hauptnavigation Level Top
        $('#mainNavToggle').unbind();
        $('#mainNavToggle').click(function(event) {
            event.preventDefault();
            var mainnav = $('#mainNav');
            if (mainnav.is(":visible")) {
                //  InitiPage.makeSticky();
                $('#mainNavToggle a.navtoggle').removeClass('open');
                $('#mainNav').slideUp('slow');
                InitiPage.overlayHide();
            } else {
                // InitiPage.clearSticky();
                // Suchfeld schliessen
                $('#searchBox').slideUp('fast');
                $("#searchIcon").removeClass("active");
                // Navi zeigen
                $('#mainNavToggle a.navtoggle').addClass('open');
                $('#mainNav').slideDown('slow');
                InitiPage.overlayShow();
            }
        });



        // Suchfeld in Navigation
        $('#searchIcon').unbind();
        $('#searchIcon').click(function(event) {
            event.preventDefault();
            var mainSearch = $('#searchBox');
            if (mainSearch.is(":visible")) {
                //   InitiPage.makeSticky();
                $("#searchIcon").removeClass("active");

                $('#searchBox').slideUp('slow');
                $('#search_suggest').html('');
                InitiPage.overlayHide();


            } else {
                //    Unsichtbar - ausfahren
                // Navi schliessen
                $('#mainNavToggle a.navtoggle').removeClass('open');
                $('#mainNav').slideUp('slow');
                // Suche zeigen
                $("#searchIcon").addClass("active");
                $('#searchBox').slideDown('slow');
                InitiPage.overlayShow();

                $('#suchfeld').focus();
                if ($(window).width() < 641) {
                    setTimeout(
                        function() {
                            $("html, body").animate({
                                scrollTop: 62
                            }, "500");
                        }, 750);

                }
            }
        });

        // Bei klick auf das ovlerlay
        $('#nav-overlay').unbind();
        $("#nav-overlay").click(function() {

            InitiPage.overlayHide();
        });


        InitiPage.iniMenue();

    }, // EndofInit-start
    loadPage: function() { //Alles was beim laden der Seite initlialisiert werden soll - aber NICHT bei rezize


        "use strict";

        InitiPage.iniMenue();

    }, // EndofLoadPage

    moveUp: function(elem, rem, remoffset) {
        "use strict";
        var remtop;
        var elemtop = elem.offset().top;

        if (rem.length > 0) {
            remtop = rem.offset().top;
        } else {
            remtop = 0;
        }

        var op = 0;
        if (remtop < elemtop) {
            op = rem.outerHeight();
        }
        op = op + remoffset; // + remoffset;
        if (rem.length > 0) {
            elemtop = elemtop - op;
        }
        $("html, body").animate({
            scrollTop: elemtop
        }, "100", "swing");
    },
    makeSticky: function() {
        "use strict";
        /*     $("#mainNavWrapper").addClass("sticky");
             $("#mainNavWrapper").css({
                 'position': 'fixed'
             });
             $("main").css({
                 'margin-top': '52px'
             });*/
    },
    iniMenue: function() {
        "use strict";



        $(' a.mainLink:not(.deepLink) ').unbind();
        $('a.subLink:not(.deepLink) ').unbind();

        if ($(window).width() <= 640) {

            console.log("resize menue <= 640 (small)");

            // Submenue Level 0
            $('ul .flyOut').hide();

            $(' a.mainLink:not(.deepLink)').click(function() {
                var trig = $(this);
                trig.blur();
                var trigFly = trig.next('ul .flyOut');

                if (trig.hasClass('active')) {
                    trigFly.slideToggle(100);
                    trig.removeClass('active');
                } else {
                    var rem = $('.active').next('ul .flyOut');
                    InitiPage.moveUp(trig, rem, 0);
                    $('.active').next('ul .flyOut').slideUp(100);
                    $('a.active').removeClass('active');
                    $('ul .subflyOut').slideUp(100);
                    $('a.subLink.active').removeClass('active');
                    trigFly.slideDown(100);
                    trig.addClass('active');
                }
                return false;
            });


            // Submenue Level 1 (Destinationen)

            $('a.subLink:not(.deepLink) ').click(function() {
                var trigSub = $(this);
                trigSub.blur();
                var trigFlySub = trigSub.next('ul .subflyOut');
                if (trigSub.hasClass('active')) {
                    trigFlySub.slideToggle(100);
                    trigSub.removeClass('active');
                } else {
                    var rem = $('.active').next('ul .subflyOut');
                    InitiPage.moveUp(trigSub, rem, 0);
                    $('.active').next('ul .subflyOut').slideUp(100);
                    $('a.subLink.active').removeClass('active');
                    trigFlySub.slideDown(100);
                    trigSub.addClass('active');
                }
                return false;
            });

        } else {
            console.log("resize menue > 640");
            $('ul .subflyOut').show();
            $('ul .flyOut').show();
            $('a.subLink.active').removeClass('active');
            $(' a.mainLink:not(.deepLink)').removeClass('active');
        }


    },

    headhide: function() {
        "use strict";
        $('#mainNavWrapper').removeClass("hidenav");
        if ($('#mainNavWrapper').is(":visible")) {
            $('#mainNavWrapper').slideUp('fast');
        }
    },
    headshow: function() {
        "use strict";
        $('#mainNavWrapper').addClass("hidenav");
        if (!$('#mainNavWrapper').is(":visible")) {
            $('#mainNavWrapper').slideDown('fast');
        }
    },

    overlayHide: function() {
        "use strict";
        $("#nav-overlay").fadeOut().css({
            'height': '0',
            'width': '0px'
        });

        // Navigation schliessen
        $('#mainNavToggle a.navtoggle').removeClass('open');
        $('#mainNav').slideUp('fast');
        $('#searchBox').slideUp('fast');
        $('#search_suggest').html('');
        $("#searchIcon").removeClass("active");

    },
    overlayShow: function() {
        "use strict";
        var bbreite = $(window).width();
        var doc_height = $(document).height();
        doc_height = doc_height - 50;
        $("#nav-overlay").css({
            'height': doc_height,
            'width': bbreite + 'px'
        }).fadeIn();
    },
    mobilenoteshow: function() {
        "use strict";
        var ov_breite = $(window).width();
        var ov_height = $(document).height();
        //	InitiPage.clearSticky();
        $('#all-overlay').css({
            'height': ov_height,
            'width': ov_breite + 'px'
        }).fadeIn();
        $('div#mobile-note').fadeIn();

    },
    mobilenoteshide: function() {
        "use strict";
        //	InitiPage.makeSticky();
        $('#all-overlay').hide();
        $('div#mobile-note').hide();


    },
    goToUri: function(uri, href) {
        "use strict";
        var start, end, elapsed;
        start = new Date().getTime();
        document.location = uri;
        end = new Date().getTime();
        elapsed = (end - start);
        if (elapsed < 1) {
            document.location = href;
        }
    }
};

// Starten der Initialisierung der Menüs
jQuery(function($) {

    //	InitiPage.makeSticky();


    InitiPage.start();
    InitiPage.loadPage();

});
$(document).ready(function() {
    "use strict";


    // smoothes Scrollen zur Hotel-Karte
    $('.jq_show_hotel').not("#jq_top").bind('click', function(event) {
        event.preventDefault();
        // NEU: Jetzt fest auf "Gmap" verlinkt
        // var jumpid = $(this).attr('href'),
        var jumpid = '#Gmap',
            mainNav = $('.topNav').height(),
            subNav = $('.subnav').height(),
            topoffset = 1 - (mainNav + subNav);
        // TODO: für small wieder deaktivieren - keine sticky nav
        // if (Foundation.utils.is_small_only()) {
        //   topoffset = 0;
        // }
        //  alert ("mit subnav" +id + ' bei ' + topoffset);
        $.scrollTo(
            jumpid, {
                duration: 200,
                offset: {
                    'left': 0,
                    'top': topoffset
                }
            }
        );
    });



    $(window).on('resize', function() {
        // alert ("Resize Event");
        window.resizeEvt;
        $(window).resize(function() {
            clearTimeout(window.resizeEvt);
            window.resizeEvt = setTimeout(function() {
                InitiPage.start();
            }, 250);
        });
    });

    $(window).on("orientationchange", function() {
        InitiPage.start();
        if ($('#mainNav').is(":visible")) {
            $('html, body').animate({
                scrollTop: 0
            }, 'fast');
        }
        InitiPage.loadPage();
    });

    var isMobile = jQuery.browser.mobile;

    if (isMobile) {

        $('a.intent').on('click', function(event) {
            InitiPage.goToUri($(this).data('scheme'), $(this).attr('href'));
            event.preventDefault();
        });
    }



}); // EofJQReady

function is_iOS() {
    var iDevices = [
        'iPad Simulator',
        'iPhone Simulator',
        'iPod Simulator',
        'iPad',
        'iPhone',
        'iPod'
    ];

    while (iDevices.length) {
        if (navigator.platform === iDevices.pop()) {
            return true;
        }
    }

    return false;
}

