$(document).foundation(); // WICHTIG: Intialisiert alle Foundation-Sachen

$(document).ready(function () {
  $("body").removeClass("dom-loading");
});
$(window).on("load", function () {
  $("body").removeClass("loading");
});


// MASONRY
// window.load: erst wenn alle Bilder da sind Masonry starten
$(window).load(function() {
  // init Masonry after all images have loaded
  masonry_update();
  masonry_update_alt();
});

$(document).ready(function() {
  masonry_update();
  masonry_update_alt();
});

var resizeId;
$(window).resize(function() {
  clearTimeout(resizeId);
  resizeId = setTimeout(doneResizing, 500);
});

function doneResizing() {
  masonry_update();
  masonry_update_alt();
}

// Zwei verschiedene Masonry-container, z.b. für die PhotoSwipe-Galerie (für Mobile und Desktop = 2 Versionen)
var $masonry_list = $("#masonryContainer");
var $masonry_list_alt = $("#masonryContainerAlt");

function masonry_update() {
  setTimeout(function() {
    $masonry_list.masonry({
      itemSelector: ".masonry-brick",
      initLayout: false,
      transitionDuration: "0.3s"
    });
    // console.log("masonry update");
  }, 10);
  $masonry_list.masonry();
}
function masonry_update_alt() {
  setTimeout(function() {
    $masonry_list_alt.masonry({
      itemSelector: ".masonry-brick",
      initLayout: false,
      transitionDuration: "0.3s"
    });
    // console.log("masonry update (zweiter Container)");
  }, 10);
  $masonry_list_alt.masonry();
}
if ($("#masonryContainer").length) {
  document
    .getElementById("masonryContainer")
    .addEventListener("load", masonry_update, true);
}
if ($("#masonryContainerAlt").length) {
  document
    .getElementById("masonryContainerAlt")
    .addEventListener("load", masonry_update_alt, true);
}

// // Modal-Fenster nach bestimmter Anzahl von Zeichen (nur Mobil) - mit Foundation "Reveal"
// if ($(window).width() <= 640) {
//   var id = 0;
//   $(".modal-on-mobile").each(function() {
//     // Nach "data-charbreak"-Wert wird der Text abgeschnitten (Anzahl Zeichen). Default ganz hoch, damit bei leerem Wert nichts abgeschnitten wird (für's CMS)
//     var maxLength = $(this).data("charbreak") || 99999999;
//     var myStr = $(this).html();
//     // IDs mitlaufen lassen
//     id++;
//     if (myStr.length > maxLength) {
//       var newStr = myStr.substring(0, maxLength);
//       var removedStr = myStr.substring(maxLength, myStr.length);
//       var closeModal =
//         '<div class="reveal-close"><button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true"><small>schließen</small> &times;</span></button></div>';
//       // Anfangs-Text
//       $(this)
//         .empty()
//         .html(newStr);
//       // "Mehr"-Button
//       $(this).append(
//         '<a data-open="mobileModal' + id + '"> weiterlesen...</a>'
//       );
//       // Modalfenster mit dem gesamten Text
//       $(this).append(
//         '<div class="full reveal" id="mobileModal' +
//           id +
//           '" data-reveal data-options="animationIn: scale-in-up; animationOut: scale-out-down; deepLink: true; closeOnClick: false">' +
//           closeModal +
//           newStr +
//           removedStr +
//           "</div>"
//       );
//       // Bei Foundation 6 gibt es statt reflow einfach $(this).foundation(); - hier notwendig damit 'reveal' wieder greift
//       $(this).foundation();
//     }
//   });
// }



// -----------------------------------------------------------------------------
// READMORE 
// -----------------------------------------------------------------------------
// Modal-Fenster oder Text ausklappbar, nach bestimmter Anzahl von Zeichen. 
// Beispiele:
// <div class="readmore" data-readmore="500">
// --> Nach 500px Höhe Link zum ausklappen.
// <div class="readmore readmore--modal readmore--small-only" data-readmore="500">
// --> Nach 500px Höhe Link auf Modal. Nur Mobil 

$(document).ready(function () {
  let id = 0;

  $(".readmore").each(function () {
      let $this = $(this);
      // Nach "data-readmore"-Wert wird der Text abgeschnitten (Anzahl Zeichen). 
      // Default-Wert ganz hoch, damit bei leerem Wert nichts abgeschnitten wird (für's CMS)
      var maxHeight = $this.data('readmore') || 99999999;

      // Höhe begrenzen, wenn Inhalt länger als data-readmore 
      // (30px Luft eingeplant, damit sich das ausklappen auch lohnt)
      if ($this.height() > maxHeight + 30) {
          if ($this.hasClass("readmore--small-only") && Foundation.MediaQuery.current !== "small") {
              $this.addClass("is-inactive");
              return true;
          } else {
              $this.css({
                  "max-height": maxHeight + "px"
              });
          }
      } else {
          $this.addClass("is-inactive");
          return true;
      }

      // VARIANTE 1: LIGHTBOX
      // <div class="readmore readmore--modal" data-readmore="400"></div>
      if ($this.hasClass("readmore--modal")) {

          // IDs mitlaufen lassen
          id++;
          // Content abnehmen
          var modalContent = $this.html();
          // Schließenbutton 
          var closeModalButton = '<div class="reveal-close"><button class="close-button" data-close aria-label="Close modal" type="button"><span aria-hidden="true"><small>schließen</small> &times;</span></button></div>';

          // Lightbox-Option A: Foundation Reveal
          // "Mehr"-Button
          $this.append('<a class="readmore__more" data-open="mobileModal' + id + '"> weiterlesen...</a>');
          // Modalfenster mit dem gesamten Text
          $this.append('<div class="reveal" id="mobileModal' + id + '" data-reveal data-options="deepLink: true; updateHistory: true;">' + closeModalButton + modalContent + '</div>');
          // Bei Foundation 6 gibt es statt reflow einfach $this.foundation(); - hier notwendig damit 'reveal' wieder greift
          $this.foundation();

          // Lightbox-Option B: Fancybox
          // // "Mehr"-Button
          // $this.append('<a class="readmore__more" data-fancybox data-src="#mobileModal' + id + '"> weiterlesen...</a>');
          // // Modalfenster mit dem gesamten Text
          // $this.append('<div id="mobileModal' + id + '" style="display:none; max-width: 1000px;">' + modalContent + '</div>');
      }
      // VARIANTE 2: Ausklappen
      // <div class="readmore" data-readmore="400"></div>
      else {
          $this.append(' <a class="readmore__more">mehr lesen...</a>');
          $this.append(' <a class="readmore__less">...weniger lesen</a>');

          $this.on("click", ".readmore__more", function () {
              $this.addClass("is-expanded");
              $this.css({
                  "max-height": "none"
              });
          });

          $this.on("click", ".readmore__less", function () {
              $this.removeClass("is-expanded");
              $this.css({
                  "max-height": maxHeight + "px"
              });
          });
      }
  });
});


var topNav = $(".topNav");
var stickySubnav = $(".subnav .sticky");
if (stickySubnav.length > 0) {
  if (stickySubnav.hasClass("is-stuck") || stickySubnav.offset().top < 100) {
    topNav.addClass("subnav-attached");
    stickySubnav.addClass("subnav-attached");
  } else {
    topNav.removeClass("subnav-attached");
    stickySubnav.removeClass("subnav-attached");
  }
}

// Scrolling-Funktionen (sind einige)

$(window).scroll(function() {
  // Booking-Reminder: extra-padding für Footer
  var footer = $("footer");
  if (
    $("#booking_reminder").is(":visible") &&
    $("#booking_reminder").hasClass("is-stuck")
  ) {
    footer.addClass("sticky-incoming");
  } else {
    footer.removeClass("sticky-incoming");
  }
  // Außerdem hier mit rein: doppelten Schatten der Sticky-Nav entfernen (TopNav bekommt neue Klasse)
  if (stickySubnav.length > 0) {
    if (stickySubnav.hasClass("is-stuck") || stickySubnav.offset().top < 100) {
      topNav.addClass("subnav-attached");
    } else {
      topNav.removeClass("subnav-attached");
    }
  }
  // Parallax - Scrolling

  if ($(".parallax-img").length > 0) {
    var parallaxBild = $(".parallax-img");
    if ($(window).width() >= 1025) {
      // console.log("Width = " + $(window).width());
      // Gescrollte Distanz:
      var offset = $(window).scrollTop();
      // Bild, das Parallax scrollen soll

      // Mit data-parallax-friction kann der die Scrollrate festgelegt werden, Default ist 0.2
      var friction = parallaxBild.data("friction") || 0.35;
      // Damit das ganze 'parallax' wird: gescrollte Distanz mal 0.X -> Bild scrollt mit, aber langsamer / weniger
      var moving = Math.floor(offset * friction);
      // nur positive Werte, wg. Safari-'Bounce'-Effekt beim hochscrollen
      if (moving < 0) {
        moving = 0;
      }
      parallaxBild.css({
        transform: "translateY(" + moving + "px)",
        "-webkit-transform": "translateY(" + moving + "px)"
      });
    } else {
      parallaxBild.css({
        transform: "translateY(" + 0 + "px)",
        "-webkit-transform": "translateY(" + 0 + "px)"
      });
    }
  }
});

// Datepicker "Flatpickr"
// https://chmln.github.io/flatpickr/examples/#disabling-specific-dates
// WICHTIG: Entweder Klasse ODER Id vergeben, sonst wird der Datepicker doppelt
//  ausgeführt

// KLASSE: Allgemeiner Datepicker
// <div class="js-datepicker">...
$(document).ready(function () {

  var isMobile = true;
  if ($(window).width() >= 640) {
    isMobile = false;
  }

  // mobile: Inputfeld an oberen Rand scrollen (bei allen Instanzen im Einsatz)
  // Als Parameter jeweils das 'this' der Flatpickr-Instance übergeben
  function scrollToDatepickField(FlatpickrInstance) {
    if (isMobile) {
      setTimeout(() => {
        // Der Datepicker kann von oben oder unten aufs Inputfeld zeigen. 
        // nur hochscrollen, wenn er von unten darauf zeigt
        if (!FlatpickrInstance.calendarContainer.classList.contains("arrowBottom")) {
          // console.log("!FlatpickrInstance.calendarContainer.classList.contains(arrowBottom): ", !FlatpickrInstance.calendarContainer.classList.contains("arrowBottom"));
          $('html, body').animate({
            scrollTop: $(FlatpickrInstance.altInput).offset().top - 100
          }, 500);
        } else {
          // ansonsten das Input-Feld an den unteren Viewport scrollen
          $('html, body').animate({
            scrollTop: $(FlatpickrInstance.altInput).offset().top - $(window).height() + 60
          }, 500);
        }
      }, 10);
    }
  }

  // Datepicker die mit Angaular verbunden sind etwas verzögert initialisieren,
  // damit zuerst das evtl. Default-Datum übergeben werden kann
  if ($(".js-datepicker").not("[ng-model]").length) {
    $(".js-datepicker").not("[ng-model]").flatpickr({
      locale: "de",
      altFormat: "l d.m.Y",
      altInput: true,
      mode: "single",
      animate: false,
      disableMobile: true,
      minDate: new Date(),
      maxDate: new Date().setFullYear(new Date().getFullYear() + 2),
      onOpen: function () {
        scrollToDatepickField(this);
        // Blur für iOS
        setTimeout(() => {
          $(this.altInput).blur();
        }, 10);
      },
      onClose: function () {
        $(this.altInput).blur();
      },
      onReady: function() {
        // console.log("Datepicker ready: ", this);
      }
    });
  }

  if ($(".js-datepicker[ng-model]").length) {
    setTimeout(() => {
      $(".js-datepicker[ng-model]").flatpickr({
        locale: "de",
        altFormat: "l d.m.Y",
        altInput: true,
        mode: "single",
        animate: false,
        disableMobile: true,
        minDate: new Date(),
        maxDate: new Date().setFullYear(new Date().getFullYear() + 2),
        onOpen: function () {
          scrollToDatepickField(this);
          // Blur für iOS
          setTimeout(() => {
            $(this.altInput).blur();
          }, 10);
        },
        onClose: function () {
          $(this.altInput).blur();
        },
        onReady: function() {
          // console.log("Datepicker ready (ng-modal, with timeout): ", this);
        }
      });
    }, 1000);
  }



  // ID: Von- und -bis Datepicker
  // <div id="js-datepicker--to">...
  if ($("#js-datepicker--to").length && $("#js-datepicker--from").length) {
    var datepickerFrom = document.getElementById("js-datepicker--from");
    var datepickerTo = document.getElementById("js-datepicker--to");

    var fromDateCalendar = new flatpickr(datepickerFrom, {
      locale: "de",
      altFormat: "d. F Y",
      altInput: true,
      mode: "single",
      animate: false,
      disableMobile: true,
      minDate: new Date(),
      maxDate: new Date().setFullYear(new Date().getFullYear() + 2),
      // minDate des "bis"-Datepickers einstellen: Nicht kleiner als "Ab"-Datepicker
      onChange: function (selectedDates, dateStr, instance) {
        // selectedDates ist ein Array, also auf den ersten (und einzigen Eintrag) wählen
        var newMinDate = selectedDates[0];
        toDateCalendar.set("minDate", newMinDate);
        if (!isMobile) {
          toDateCalendar.open();
        }
      },
      onClose: function () {
        $(this.altInput).blur();
      },
      onOpen: function () {
        scrollToDatepickField(this);
        // Blur für iOS
        setTimeout(() => {
          $(this.altInput).blur();
        }, 10);
      },
    });
    var toDateCalendar = new flatpickr(datepickerTo, {
      locale: "de",
      altFormat: "d. F Y",
      altInput: true,
      mode: "single",
      animate: false,
      disableMobile: true,
      minDate: new Date(),
      maxDate: new Date().setFullYear(new Date().getFullYear() + 2),
      onClose: function () {
        $(this.altInput).blur();
      },
      onOpen: function () {
        scrollToDatepickField(this);
        // Blur für iOS
        setTimeout(() => {
          $(this.altInput).blur();
        }, 10);
      },
    });
  }
});


// Interne Links gemächlich anscrollen
// nur wenn diese Links die Klasse "smooth-scroll" haben
// https://css-tricks.com/snippets/jquery/smooth-scrolling/
$(function() {
  $('a.smooth-scroll[href*="#"]:not([href="#"])').click(function() {
    if (
      location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
      location.hostname == this.hostname
    ) {
      var target = $(this.hash);
      target = target.length ? target : $("[name=" + this.hash.slice(1) + "]");
      let targetOffset = target.offset().top;
      // Desktop mehr Luft nach oben wegen sticky Header
      if ($(window).width() > 640) {
        targetOffset -= 100;
      }
      if (target.length) {
        $("html, body").animate(
          {
            scrollTop: targetOffset
          },
          1000
        );
        // "nach-oben"-elevator hier nicht zeigen
        if ($("#elevator").length) {
          $("#elevator").addClass("hide");
          setTimeout(function() {
            $("#elevator").removeClass("hide active");
          }, 1500);
        }
        return false;
      }
    }
  });
});


// -----------------------------------------------------------------------------
// BOOKING
// -----------------------------------------------------------------------------
// Buchungssteuerung
// -----------------------------------------------------------------------------

// in dist triggert die Funktion durch die Klasse am Button. In der Live-Umgebung ruft Angular die Funktion direkt ab (ohne Klasse).
$(".jq_zus-booking-on").on("click", function(e){
    e.preventDefault();
    zusBookingOn();
    // DEV-Mode, nur bei /dist/-Variante, Klasse sonst über Angular gesetzt
    if (window.location.pathname.indexOf("/dist/") > -1) {
      $("body").addClass("bookingform-open");
      setTimeout(() => {
        $("body").removeClass("loading-again");        
      }, 500);
    }
});

// nur für HTML-Demo
$(".jq_zus-booking-off").on("click", function(e){  
    zusBookingOff(); 
    // DEV-Mode, nur bei /dist/-Variante, Klasse sonst über Angular gesetzt
    if (window.location.pathname.indexOf("/dist/") > -1) {
      $("body").removeClass("bookingform-open");
      setTimeout(() => {
        $("body").removeClass("loading-again");        
      }, 500);
    }
});



// Folgende Funktionen werden von Gregor über Angular getriggert
// zusBookingOn / zusBookingOff: Formular ein + ausblenden (Rest wird über CSS und body.bookingform-open gesteuert. Die body-Klasse wird von Gregor gesetzt)
// zusBookingInit / zusBookingReinit: FoundationElemente (accordion) starten bzw. reinitialisieren, wenn Angular mit dem rendern fertig ist
var zusBookingOn = function() {
    // console.log("zusBookingOn");
    $("body").addClass("loading-again");
    $("html, body").animate({
        scrollTop: 0
    }, 300);

    // Overlay entfernen nach 5sek nur als Fallback.
    // zusBookingInit() bzw. zusBookingReinit() entfernt das, sobald Angular fertig gerendert hat
    setTimeout(function() {
      $("body").removeClass("loading-again");
    }, 5000);  
};

var zusBookingOff = function() {
    // console.log("zusBookingOff");
    $("body").addClass("loading-again");
    setTimeout(function() {
        $("body").removeClass("loading-again");
    }, 800);
    $(window).trigger("resize");
};

var zusBookingInit = function() {
    // console.log("zusBookingInit");
    setTimeout(function() {
        $("body").removeClass("loading-again");
    }, 100);
    $("#bookingFormContainer .accordion").foundation();
    // Googletag-Manager-Trigger (von Michael)
    var dataLayer = window.dataLayer = window.dataLayer || [];
    dataLayer.push({
        'event':'checkout',
        'virtualPageURL':'/buchung/schritt1',
        'virtualPageTitle' : 'Buchungsformular'
    });
};

var zusBookingReinit = function() {
    // console.log("zusBookingReinit");
    setTimeout(function() {
        $("body").removeClass("loading-again");
    }, 100);
    Foundation.reInit($("#bookingFormContainer .accordion"));
    // Tooltip muss ganz frisch initialisiert werden, reinit klappt da nicht
    $('#bookingFormContainer .has-tip').foundation();
};

var zusBookingInvalid = function() {
    // console.log("zusBookingInvalid");
    // timeout damit Angular die .error-Klasse zuerst setzt, dann die nicht-validen Accordions ausklappen
    setTimeout(function() {
      if ($("#bookingFormContainer").find(".accordion-item.error").length > 0) {
         $("#bookingFormContainer .accordion").foundation('down', $(".accordion-item.error .accordion-content"));
      } else {
        console.warn("Kein accordion-item mit Klasse 'error' gefunden.");
      }
    }, 50);
    // zum ersten Fehlerfeld scrollen (30px Luft für's label)
    setTimeout(function() {
        if ($("#bookingFormContainer").find(".error:not(.accordion-item)").length > 0) {
            var firstInputError =  $("#bookingFormContainer").find(".error:not(.accordion-item)").first();
            $("html, body").animate({
                scrollTop: firstInputError.offset().top - 30
            }, 250);
        } else {
            console.warn("Kein Formular-Element mit Klasse 'error' gefunden.");
        }
    }, 60);
    // "nach-oben"-elevator hier nicht zeigen
    $("#elevator").addClass("hide");
    setTimeout(function() {
        $("#elevator").removeClass("hide active");
    }, 500);
};


// Accordion-Steuerung durch "weiter"-button
$(document).ready(function() {
    // Listener auf dem body, weil das Buchungsformular nachgeladen wird
    $("body").on("click", ".jq_next-panel", function(e){
        e.preventDefault();
        // console.log("weiter geclicked");
        var bookingAcc = $("#bookingFormContainer .accordion");
        // aktuelles Panel schließen
        bookingAcc.foundation('up', $(this).closest("li").find(".accordion-content"));
        // dann nächstes Panel öffnen
        bookingAcc.foundation('down', $(this).closest("li").next("li").find(".accordion-content"));
        // zum richtigen Punkt scrollen
        var nextPanelOffset = $(this).closest("li").next("li").offset().top;
        var thisContentHeight = $(this).closest(".accordion-content").outerHeight();
        // nächster panel-offset - aktuelle Inhaltshöhe = richtige Scrolldistanz
        var scrollTarget = nextPanelOffset - thisContentHeight;
        // console.log("nextPanelOffset: " + nextPanelOffset);
        // console.log("thisContentHeight: " + thisContentHeight);
        // console.log("scrollTarget: " + scrollTarget);
        $("html, body").animate({
            scrollTop: scrollTarget
        }, 200);
        // "nach-oben"-elevator hier nicht zeigen
        $("#elevator").addClass("hide");
        setTimeout(function() {
            $("#elevator").removeClass("hide active");
        }, 500);
    });
});

// Nach-oben-scroll - Button (nur mobil)
$(document).ready(function() {
  if ($(window).width() < 640) {
    var lastScrollTop = 0;
    $(window).scroll(
      $.throttle(300, function() {
        var scrollTop = $(window).scrollTop();
        var elevator = $("#elevator");
        // Nur beim hochscrollen einblenden, und erst nach einer gewissen Scroll-Distanz:
        if (scrollTop < lastScrollTop && scrollTop > 700) {
          elevator.addClass("active");
        } else {
          //  beim runterscrollen ausblenden
          elevator.removeClass("active");
        }
        lastScrollTop = scrollTop;
      })
    );
  }
});


// -----------------------------------------------------------------------------
// VIDEOS
// -----------------------------------------------------------------------------
$(document).ready(function () {
  // ipad: intro-Bild statt video zeigen (iPad blockiert autoplay)
  // TODO: sauberer lösen?
  if ($(".video_contain").length > 0) {
      if (navigator.userAgent.match(/(iPod|iPhone|iPad)/)) {
          //Bei iPad: video ausblenden + bild stattdessen zeigen
          $(".intro-img.show-for-large").hide();
          $(".intro-img.hide-for-large").removeClass("hide-for-large");
      }
  }

  // Video-Start/Pause bei Hover 
  //  Parent bekommt Klasse "js-hover-video"
  if (Foundation.MediaQuery.atLeast('medium')) {
      $(".js-hover-video").each(function () {
          if ($(this).find("video").length) {
              $(this).on("mouseover", function () {
                  $(this).find("video")[0].play();
              });
              $(this).on("mouseleave", function () {
                  // nur pausieren wenn Video schon bereit ist https://bugs.chromium.org/p/chromium/issues/detail?id=593273
                  // Gibt sonst Fehler
                  if ($(this).find("video").readyState === 4) {
                      $(this).find("video").pause();
                  }
              });
          }
      });
  }
  

  // Als eine Art srcset für Video: source[data-large-src] für die große Version,
  // mobile Variante steht in Standard-src
  if (Foundation.MediaQuery.atLeast('large')) {
      var largeSrc = $(".video_contain").find("source").attr("data-large-src");
      var $video = $(".video_contain").find("video");

      if (largeSrc != undefined && $video.length) {
          console.log("largeSrc exists");
          $video.find("source").attr("src", largeSrc);
          setTimeout(() => {
              $video[0].load();
              $video[0].play();
              console.log("src replaced: ", largeSrc);
          }, 20);
      }
  }

  // iOS im Stromsparmodus startet Videos nicht autmatisch, sondern zeigt einen 
  // Play-Button an, der aber nicht erreichbar ist wenn unsere intro-img-text 
  // drüber liegt. Hiermit den Play-Befehl manuell ans Video weiterreichen
  if ($(".intro-img video").length > 0) {
    $(".intro-img").on("click", function() {
        $(this).find("video")[0].play();
    });
  }
});




$(document).ready(function () {
  // Alle mobil ausgeblendeten "autoplay" Videos aus dem DOM entfernen  
  // Firefox spielt die auch ab, wenn die auf display:none gesetzt sind
  // Ausnahme: videos mit 'playsinline'-Attribut, die sollen auch mobil angezeigt werden
  if (!Foundation.MediaQuery.atLeast('large')) {
    if ($("video[autoplay]").not("[playsinline]").length > 0) {
            $("video[autoplay]").not("[playsinline]").remove();
    }
  }
});

// Ähnlich: allgemeinere Helferklasse um mobil Elemente (wie ein Video) aus dem 
// Quellcode zu löschen. 
// In purem JS um das schneller ausführen zu können
if (window.innerWidth < 640) {
  document.querySelectorAll('.remove-for-small').forEach(function(node) {
    node.remove();
  }); 
}



// Photoswipe-
var initPhotoSwipeFromDOM = function(gallerySelector) {
  // parse slide data (url, title, size ...) from DOM elements
  // (children of gallerySelector)
  var parseThumbnailElements = function(el) {
    var thumbElements = el.childNodes,
      numNodes = thumbElements.length,
      items = [],
      figureEl,
      linkEl,
      size,
      item;

    for (var i = 0; i < numNodes; i++) {
      figureEl = thumbElements[i]; // <figure> element

      // include only element nodes
      if (figureEl.nodeType !== 1) {
        continue;
      }

      linkEl = figureEl.children[0]; // <a> element

      size = linkEl.getAttribute("data-size").split("x");

      // create slide object
      item = {
        src: linkEl.getAttribute("href"),
        w: parseInt(size[0], 10),
        h: parseInt(size[1], 10)
      };

      if (figureEl.children.length > 1) {
        // <figcaption> content
        item.title = figureEl.children[1].innerHTML;
      }

      if (linkEl.children.length > 0) {
        // <img> thumbnail element, retrieving thumbnail url
        item.msrc = linkEl.children[0].getAttribute("src");
      }

      item.el = figureEl; // save link to element for getThumbBoundsFn
      items.push(item);
    }

    return items;
  };

  // find nearest parent element
  var closest = function closest(el, fn) {
    return el && (fn(el) ? el : closest(el.parentNode, fn));
  };

  // triggers when user clicks on thumbnail
  var onThumbnailsClick = function(e) {
    e = e || window.event;
    e.preventDefault ? e.preventDefault() : (e.returnValue = false);

    var eTarget = e.target || e.srcElement;

    // find root element of slide
    var clickedListItem = closest(eTarget, function(el) {
      return el.tagName && el.tagName.toUpperCase() === "FIGURE";
    });

    if (!clickedListItem) {
      return;
    }

    // find index of clicked item by looping through all child nodes
    // alternatively, you may define index via data- attribute
    var clickedGallery = clickedListItem.parentNode,
      childNodes = clickedListItem.parentNode.childNodes,
      numChildNodes = childNodes.length,
      nodeIndex = 0,
      index;

    for (var i = 0; i < numChildNodes; i++) {
      if (childNodes[i].nodeType !== 1) {
        continue;
      }

      if (childNodes[i] === clickedListItem) {
        index = nodeIndex;
        break;
      }
      nodeIndex++;
    }

    if (index >= 0) {
      // open PhotoSwipe if valid index found
      openPhotoSwipe(index, clickedGallery);
    }
    return false;
  };

  // parse picture index and gallery index from URL (#&pid=1&gid=2)
  var photoswipeParseHash = function() {
    var hash = window.location.hash.substring(1),
      params = {};

    if (hash.length < 5) {
      return params;
    }

    var vars = hash.split("&");
    for (var i = 0; i < vars.length; i++) {
      if (!vars[i]) {
        continue;
      }
      var pair = vars[i].split("=");
      if (pair.length < 2) {
        continue;
      }
      params[pair[0]] = pair[1];
    }

    if (params.gid) {
      params.gid = parseInt(params.gid, 10);
    }

    return params;
  };

  var openPhotoSwipe = function(
    index,
    galleryElement,
    disableAnimation,
    fromURL
  ) {
    var pswpElement = document.querySelectorAll(".pswp")[0],
      gallery,
      options,
      items;

    items = parseThumbnailElements(galleryElement);

    // define options (if needed)
    options = {
      // define gallery index (for URL)
      galleryUID: galleryElement.getAttribute("data-pswp-uid"),

      getThumbBoundsFn: function(index) {
        // See Options -> getThumbBoundsFn section of documentation for more info
        var thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
          pageYScroll =
            window.pageYOffset || document.documentElement.scrollTop,
          rect = thumbnail.getBoundingClientRect();

        return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
      }
    };

    // PhotoSwipe opened from URL
    if (fromURL) {
      if (options.galleryPIDs) {
        // parse real index when custom PIDs are used
        // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
        for (var j = 0; j < items.length; j++) {
          if (items[j].pid == index) {
            options.index = j;
            break;
          }
        }
      } else {
        // in URL indexes start from 1
        options.index = parseInt(index, 10) - 1;
      }
    } else {
      options.index = parseInt(index, 10);
    }

    // exit if index not found
    if (isNaN(options.index)) {
      return;
    }

    if (disableAnimation) {
      options.showAnimationDuration = 0;
    }

    // Pass data to PhotoSwipe and initialize it
    gallery = new PhotoSwipe(pswpElement, PhotoSwipeUI_Default, items, options);
    gallery.init();
  };

  // loop through all gallery elements and bind events
  var galleryElements = document.querySelectorAll(gallerySelector);

  for (var i = 0, l = galleryElements.length; i < l; i++) {
    galleryElements[i].setAttribute("data-pswp-uid", i + 1);
    galleryElements[i].onclick = onThumbnailsClick;
  }

  // Parse URL and open gallery if it contains #&pid=3&gid=1
  var hashData = photoswipeParseHash();
  if (hashData.pid && hashData.gid) {
    openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true, true);
  }
};

// execute above function
$(window).load(function() {
  if ($(".gallery-small").is(":visible")) {
    initPhotoSwipeFromDOM(".gallery-small");
  }
  if ($(".gallery-large").is(":visible")) {
    initPhotoSwipeFromDOM(".gallery-large");
  }
});

// Lazysizes: Konfiguration für Hintergrundbilder-Lazyload
window.lazySizesConfig = window.lazySizesConfig || {};
window.lazySizesConfig.customMedia = {
    'small': '(max-width: 640px)',
    'medium': '(max-width: 1024px)'
};
